import React, { Suspense, lazy } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import styles from './styles/app.module.scss';
import { Stack } from '@mui/material';
import { HERO_VIDEO } from 'services/config';
import GigaStarLogoIcon from 'components/common/GigaStarLogo';

import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { queryClient } from 'services/providers/tanstackClient';

const ApplyForDropPage = lazy(async () => await import('pages/ApplyForDrop'));
const ChannelRequirements = lazy(async () => await import('components/common/ChannelRequirements'));
const MuiStyleProvider = lazy(async () => await import('services/providers/muiThemeProvider'));
const ApprovedPage = lazy(async () => await import('pages/Approved/approved'));
const InReviewPage = lazy(async () => await import('pages/InReview/review'));
const DeniedRevenuePage = lazy(async () => await import('pages/Denied/denied'));
const ErrorPage = lazy(async () => await import('pages/Error/error'));
const HeroVideo = lazy(async () => await import('components/common/HeroVideo'));
const Footer = lazy(async () => await import('components/common/Footer'));

import('hooks/useSentry')
    .then(module => {
        module.initSentry();
    })
    .catch(error => {
        console.error('Failed to initialize Sentry:', error);
    });

import('hooks/useGA')
    .then(module => {
        const GA = module.useGoogleAnalytics();
        GA?.initGTM();
    })
    .catch(error => {
        console.error('Failed to initialize Google Analytics:', error);
    });

const App = (): React.ReactElement => {
    const location = useLocation();
    const isViewApplicationRoute = location.pathname.startsWith('/application/');

    return (
        <Suspense fallback={null}>
            <MuiStyleProvider>
                <QueryClientProvider client={queryClient}>
                    <>
                        <Stack flexDirection='column' alignItems='center' className={styles.hero}>
                            <HeroVideo
                                videoSrc={HERO_VIDEO}
                                overlay={true}
                                circleBlurs
                                showVideo={!isViewApplicationRoute}
                            />
                            <Stack flexDirection='column' alignItems='center' className={styles.heroContent}>
                                <GigaStarLogoIcon />
                                <Routes>
                                    <Route path='/' element={<ApplyForDropPage />} />
                                    <Route path='/approved' element={<ApprovedPage />} />
                                    <Route path='/underReview' element={<InReviewPage />} />
                                    <Route path='/notApproved' element={<DeniedRevenuePage />} />
                                    <Route path='/error' element={<ErrorPage />} />
                                    <Route path='*' element={<Navigate to='/' replace />} />
                                </Routes>
                            </Stack>
                        </Stack>
                        {!isViewApplicationRoute && <ChannelRequirements />}
                        <Footer />
                    </>
                    <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
            </MuiStyleProvider>
        </Suspense>
    );
};

export default App;
