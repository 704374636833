import { GigaStarLogo } from 'assets/svgs/svgs';
import React from 'react';
import { GS_CORPORATE } from 'services/config';

const GigaStarLogoIcon = (): React.ReactElement => {
    return (
        <a
            href={GS_CORPORATE}
            target='_blank'
            rel='nooppener noreferrer'
            style={{ textDecoration: 'none', marginTop: 24, marginBottom: 24 }}
        >
            <GigaStarLogo />
        </a>
    );
};

export default GigaStarLogoIcon;
